/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import useTranslations from './useTranslations'

function JsonLd({ children }) {
    return (
        <script type="application/ld+json">{JSON.stringify(children)}</script>
    )
}

function SEO({
    description,
    lang,
    meta,
    title,
    image,
    homePage,
    recipe,
    posts,
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    )

    const { titleSite } = useTranslations()

    const metaDescription = description || site.siteMetadata.description

    const url = site.siteMetadata.siteUrl
    const ogImage = `${url}${image || '/assets/img/cover.png'}`

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${titleSite}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: ogImage,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:image:src`,
                    content: ogImage,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <link
                rel="stylesheet"
                type="text/css"
                charSet="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            {!homePage && (
                <script src="https://unpkg.com/share-api-polyfill/dist/share-min.js"></script>
            )}
            {homePage && (
                <JsonLd>
                    {{
                        '@context': 'https://schema.org',
                        '@type': 'ItemList',
                        "itemListElement": posts,
                    }}
                </JsonLd>
            )}
            {recipe && (
                <JsonLd>
                    {{
                        '@context': 'http://schema.org/',
                        '@type': 'Recipe',
                        "name": recipe.title,
                        "image":
                            'https://relaxed-gates-6dd431.netlify.app' +
                            recipe.image,
                        "author": {
                            '@type': 'Person',
                            "name": recipe.author,
                        },
                        "totalTime": recipe.prepTime,
                        "recipeYield": recipe.servings,
                    }}
                </JsonLd>
            )}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
